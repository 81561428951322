import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/swr';

import getUser from '../services/LegacyUserService/methods/getUser';

type User = Awaited<ReturnType<typeof getUser>>;

type UseGetUser = (args: {
  customerUuid: string;
  shouldMakeRequest?: boolean;
  onError?: () => void;
}) => {
  data: User,
  isLoading: boolean;
  error: Error
}

const useGetUser: UseGetUser = ({ customerUuid, shouldMakeRequest = true, onError = () => {} }) => {
  const cacheKey = `getUser/${customerUuid}`;
  const { data, error } = useSWR(shouldMakeRequest ? cacheKey : null, () => getUser({ customerUuid }), {
    revalidateOnFocus: false,
    onError
  });
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return { data, isLoading, error };
};

export default useGetUser;
