import uniqBy from 'lodash/uniqBy';
import { getProductByWrapper } from '@nm-pot/common/helpers/getProductByWrapper';
import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';

// TODO: export these types directly from nm-pot when available instead
type Drafts = NonNullable<Parameters<typeof getProductByWrapper>[1]>
type Draft = Drafts[0]

type HasValidWrapperIntent = (draft: Draft) => boolean;
const hasValidWrapperIntent: HasValidWrapperIntent = ({ wrapperIntent }) => !!wrapperIntent && wrapperIntent?.key !== WRAPPER_TYPES.GIA_ISA;

type FormatDrafts = (
  customerDrafts: NonNullable<Parameters<typeof getProductByWrapper>[1]>,
  otherDrafts: Parameters<typeof getProductByWrapper>[1]
) => {
  draftId: unknown;
  name: unknown;
  investmentStyle: unknown;
  wrapperType: unknown;
  productType: string;
}[]

export const formatDrafts: FormatDrafts = (customerDrafts, otherDrafts) => {
  const legacyLisaAndPensionPotsDrafts = [
    ...getProductByWrapper(WRAPPER_TYPES.LISA, otherDrafts),
    ...getProductByWrapper(WRAPPER_TYPES.PENSION, otherDrafts)
  ];
  const customerDraftsWithWrapperIntent = customerDrafts.filter(customerDraft => hasValidWrapperIntent(customerDraft));
  const lisaDrafts = getProductByWrapper(WRAPPER_TYPES.LISA, customerDrafts);
  const uniqueCustomerDrafts = uniqBy(
    [...customerDraftsWithWrapperIntent, ...legacyLisaAndPensionPotsDrafts, ...lisaDrafts],
    'uuid'
  );
  const drafts = uniqueCustomerDrafts.map(customerDraft => {
    const { wrapper, wrapperIntent, investmentStyle, uuid, name } = customerDraft;
    const wrapperKey = wrapperIntent?.key || wrapper.key;

    return {
      draftId: uuid,
      name,
      investmentStyle,
      // @ts-expect-error - we need to narrow the type of wrapperKey so that
      // it's no longer string, but a subtype of `keyof WRAPPER_TYPES`
      wrapperType: WRAPPER_TYPES[wrapperKey] as unknown,
      productType: wrapperKey
    };
  });

  return drafts;
};
