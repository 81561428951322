import React, { useEffect } from 'react';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { Flags, useFlag } from '@nm-utils-lib-web/flags';
import { useCustomerNextStep, STEPS } from '@nm-utils-lib-web/onboarding-journey-service';
import { generateRedirectMap } from '@nm-utils-lib-web/onboarding-journey-service/helpers/generateRedirectMap';
import { Login } from '@nm-utils-lib-web/routes';
import { useTranslation } from '@nm-utils-lib-web/translations';
import ButtonGroup from '@nutkit/component-button-group';
import { buttonCtas } from '@nutkit/component-link';
import Loading from '@nutkit/component-loading';
import Panel from '@nutkit/component-panel';
import { Heading, Text, headingLevels } from '@nutkit/component-text';

import useSignupNotification from '../../hooks/useSignupNotification';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { EVENTS, CATEGORIES } from '../../tracking';

const TRANSLATION_NAMESPACE = 'signup.appDownloadPage';

const AppDownloadPage = () => {
  const { customerUuid } = useGetAuthenticationMetadata();
  const isAppDownloadEnabled = useFlag(Flags.APP_DOWNLOAD);
  const appDownloadFlagStatus = isAppDownloadEnabled ? 'enabled' : 'disabled';
  const SignupNotification = useSignupNotification();
  const { t } = useTranslation();
  const { data: nextStep, isLoading: nextStepLoading } = useCustomerNextStep({
    customerId: customerUuid,
    currentStep: STEPS.APP_DOWNLOAD,
    shouldMakeRequest: !!customerUuid,
    params: {
      appDownloadFlagStatus
    }
  });
  const onboardingRedirectMap = generateRedirectMap({
    isOnboarding: true
  });
  const appDownloadCTAEvent = { name: EVENTS.APP_DOWNLOAD_CTA_CLICKED };
  const continueInBrowserCTAEvent = { name: EVENTS.CONTINUE_IN_BROWSER_CLICKED };
  const ctaCategory = CATEGORIES.ONBOARDING;
  const nextStepLink = customerUuid ? onboardingRedirectMap[nextStep] : Login.LOGOUT_PATH;

  useEffect(() => {
    if (isAppDownloadEnabled === false) {
      window.location.replace('/');
    }
  }, [isAppDownloadEnabled]);

  if (nextStepLoading) {
    return <Loading />;
  }

  return (
    <OnboardingLayout NotificationComponent={SignupNotification}>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subheading`)}</Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
        <Text>{t(`${TRANSLATION_NAMESPACE}.disclaimer`)}</Text>
        <ButtonGroup stackUp stackOnMobile>
          <LinkWithTracking
            href={nextStepLink}
            button
            buttonCta={buttonCtas.SECONDARY}
            eventPayload={continueInBrowserCTAEvent}
            category={ctaCategory}
            data-qa="app-download-continue-in-browser-button"
          >
            {t(`${TRANSLATION_NAMESPACE}.continueOnWeb`)}
          </LinkWithTracking>
          <LinkWithTracking
            isExternal
            href={global.NutmegConfig.APP_DOWNLOAD_URL}
            button
            buttonCta={buttonCtas.PRIMARY}
            eventPayload={appDownloadCTAEvent}
            category={ctaCategory}
          >
            {t(`${TRANSLATION_NAMESPACE}.downloadTheApp`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>
    </OnboardingLayout>
  );
};

export default AppDownloadPage;
