import React from 'react';
import Link from '@nutkit/component-link';
import { Heading, Text, textStyles } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { MARKETING_SITE_URL, NUTMEGONOMICS_URL } from '@nm-utils-lib-web/routes/marketing';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import OnboardingLayout from '../OnboardingLayout';

const TRANSLATION_NAMESPACE = 'signup.processingInfo';

export type ProcessingInformationPageProps = {
  t: (id: string) => string;
};

const ProcessingInformationPage = ({ t }: ProcessingInformationPageProps) => (
  <OnboardingLayout>
    <Heading textStyle={textStyles.TITLE_2}>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
    <Panel>
      <Heading level="2" textStyle={textStyles.TITLE_3}>
        {t(`${TRANSLATION_NAMESPACE}.panel.heading`)}
      </Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.panel.text.topText`)}</Text>
      {!isJohnLewisFinanceGBR() && (
        <Text>
          {t(`${TRANSLATION_NAMESPACE}.panel.text.inTheMeantime`)}{' '}
          <Link data-qa="processing-information__details-link" isExternal href={MARKETING_SITE_URL}>
            {t(`${TRANSLATION_NAMESPACE}.panel.links.marketing`)}
          </Link>{' '}
          {t(`${TRANSLATION_NAMESPACE}.panel.text.orCatchUp`)}{' '}
          <Link data-qa="processing-information__blog-link" isExternal href={NUTMEGONOMICS_URL}>
            {t(`${TRANSLATION_NAMESPACE}.panel.links.blogPost`)}
          </Link>{' '}
          {t(`${TRANSLATION_NAMESPACE}.panel.text.fromNutmegonomics`)}
        </Text>
      )}
    </Panel>
  </OnboardingLayout>
);

export default ProcessingInformationPage;
