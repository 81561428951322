import { errorHandler } from '@nm-utils-lib-web/monitoring';

// NOTE: error is typed as unknown as I have not yet assessed and confirmed
// every consumer of this function is throwing/rejecting with an actual Error
// instance (and not just an object literal of some sort)
const handleError = (error: unknown, description: string) => {
  errorHandler(error, { description, namespace: 'SIGN_UP' });
};

export default handleError;
