import { Status } from "../../types";

export type CheckStepCompletedStatus = (options: {
  status: Status;
  hasState: string;
}) => boolean;

export const checkCompletedStatus: CheckStepCompletedStatus = ({ status, hasState }) => {
  const isStepComplete = Boolean(status?.has?.includes(hasState));

  return isStepComplete;
};
