import React, { ComponentProps } from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { useTranslation } from '@nm-utils-lib-web/translations';

export type RouteProps = {
  render?: () => void;
  title?: string;
} & ComponentProps<typeof ReactRoute>

export const Route = ({ render, title = 'global.common.organisation.appTitle', ...props }: RouteProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTitle title={t(title)} />
      <ReactRoute {...props} render={render} />
    </>
  );
};
