export const CATEGORIES = {
  ONBOARDING: 'Onboarding'
} as const;

export const EVENTS = {
  NON_INVESTOR_DASHBOARD_CONTINUE: 'Onboarding_Non_Investor_Dashboard_Clicked_Continue',
  NON_INVESTOR_DASHBOARD_CLICK_HELP: 'Onboarding_Non_Investor_Dashboard_Clicked_Help',
  ONBOARDING_STARTED: 'Flexible_Onboarding_Started',
  ONBOARDING_ACCOUNT_CREATED: 'Onboarding_Account_Created',
  VERIFY_EMAIL_LOG_IN: 'Verify_email_log_in',
  WEALTH_SERVICES_CTA_VISIBLE: 'Wealth_Services_CTA_Visible',
  WEALTH_SERVICES_CTA_CLICKED: 'Wealth_Services_CTA_Clicked',
  LOGIN_CLICKED_FROM_REGISTRATION_PAGE: 'login_clicked_from_registration_page',
  SIGNUP_BANNER_VISIBLE: 'Signup_Banner_Visible',
  APP_DOWNLOAD_CTA_CLICKED: 'App_Download_CTA_Clicked',
  CONTINUE_IN_BROWSER_CLICKED: 'Continue_In_Browser_CTA_Clicked'
} as const;
