import { NonAuthenticatedUserService } from './LegacyUserService';
import handleError from './errorHandler';

const postVerificationEmail = async (customerUuid: string) => {
  try {
    const { data } = await NonAuthenticatedUserService.post(`/users/${customerUuid}/verification_email`);

    /**
     * NOTE: we're missing a validation layer like yup or zod to confirm at
     * runtime the values we expect from the API are actually present. As a
     * result, there's no type safe way to narrow it down. For the time being we
     * use `as`.
     * Moreover, we're casting to unknown until we know exactly the shape of
     * what we expect
     */
    return data as unknown;
  } catch (error) {
    handleError(error, 'Error sending verification email');
  }
};

export default postVerificationEmail;
