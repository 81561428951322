// Due to the time deadline this hook was moved here
// This should update utils-lib-web to add support for POST requests.
// TODO: Move to utils-lib-web
import { useState, useEffect, useCallback } from 'react';

const DEFAULT_INTERVAL = 3000;
const DEFAULT_MAX_RETRY = 5;
/* istanbul ignore file */
const noop = () => null;

export const usePolling = ({
  interval = DEFAULT_INTERVAL,
  maxRetry = DEFAULT_MAX_RETRY,
  shouldCallOnMount = true,
  fetcher,
  evaluateResponse = noop
}) => {
  const [delay, setDelay] = useState(interval);
  const [params, setParams] = useState();
  const [status, setStatus] = useState({
    LOADING: false,
    POLLING: false,
    TIMED_OUT: false
  });
  const [data, setData] = useState(null);
  const [attempt, setAttempt] = useState(0);
  const stopPolling = useCallback(
    data => {
      setDelay(null);
      setStatus({ ...status, LOADING: false, POLLING: false });
      setData(data);
    },
    [status]
  );
  const callFetcher = useCallback(
    (...params) => {
      setStatus(status => ({ ...status, LOADING: true, POLLING: true }));
      setParams(params);

      fetcher(...params)
        .then(data => evaluateResponse({ data }))
        .catch(error => {
          if (error.response && error.response.status === 500) {
            stopPolling();
          } else {
            evaluateResponse({ error });
          }
        });
    },
    [fetcher, stopPolling, evaluateResponse]
  );

  useEffect(() => {
    if (shouldCallOnMount) {
      callFetcher();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallOnMount]);

  useEffect(() => {
    let pollId;

    if (delay !== null && status.POLLING) {
      if (attempt < maxRetry) {
        pollId = setTimeout(() => {
          if (attempt === 0) {
            setStatus({ ...status, LOADING: false, POLLING: true });
          }

          callFetcher(params);
          setAttempt(attempt => attempt + 1);
        }, delay);
      } else {
        stopPolling();
        setStatus({ ...status, POLLING: false, TIMED_OUT: true });
      }
    }

    return () => clearTimeout(pollId);
  }, [delay, attempt, maxRetry, callFetcher, status, stopPolling]);

  return {
    status,
    data,
    attempt,
    callFetcher,
    stopPolling
  };
};
