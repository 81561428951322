import React, { ComponentProps, ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoute as AuthenticationProtectedRoute } from '@nm-utils-lib-web/authentication';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { useTranslation } from '@nm-utils-lib-web/translations';

export type ProtectedRouteProps = {
  render: () => ReactNode;
  title?: string;
} & ComponentProps<typeof Route>

export const ProtectedRoute = ({ render, title = 'global.common.organisation.appTitle', ...props }: ProtectedRouteProps) => {
  const { t } = useTranslation();

  return (
    <Route
      {...props}
      render={() => (
        <>
          <MetaTitle title={t(title)} />
          <AuthenticationProtectedRoute render={render} />
        </>
      )}
    />
  );
};
