import React, { ReactNode } from 'react';
import { Container, Row, Col, gutters } from '@nutkit/component-grid';

import styles from './HalfLayout.module.scss';

type HalfLayoutProps = {
  left: ReactNode;
  right: ReactNode;
}

const HalfLayout = ({ left, right }: HalfLayoutProps) => {
  return (
    <Container className={styles.container} fluid>
      <Row gutters={gutters.SM}>
        <Col sm={{ size: 10, offset: 1 }} md={{ size: 5, offset: 0 }} xl={{ size: 6, offset: 0 }}>
          {left}
        </Col>
        <Col sm={{ size: 10, offset: 1 }} md={{ size: 5, offset: 1 }} xl={{ size: 6, offset: 0 }}>
          {right}
        </Col>
      </Row>
    </Container>
  );
};

export default HalfLayout;
