import * as analytics from '@nm-utils-lib-web/analytics';

import { EVENTS, CATEGORIES } from './trackingConstants';

export const trackNonInvestorClickHelp = (props = {}) => {
  analytics.event({
    name: EVENTS.NON_INVESTOR_DASHBOARD_CLICK_HELP,
    properties: { ...props, category: CATEGORIES.ONBOARDING }
  });
};

export const trackNonInvestorContinue = (props = {}) => {
  analytics.event({
    name: EVENTS.NON_INVESTOR_DASHBOARD_CONTINUE,
    properties: { ...props, category: CATEGORIES.ONBOARDING }
  });
};

export const trackWealthServicesCTAVisible = (props = {}) => {
  analytics.event({
    name: EVENTS.WEALTH_SERVICES_CTA_VISIBLE,
    properties: {
      ...props,
      category: CATEGORIES.ONBOARDING
    }
  });
};

export const trackOnboardingStarted = () => {
  analytics.event({
    name: EVENTS.ONBOARDING_STARTED,
    properties: {
      category: CATEGORIES.ONBOARDING
    }
  });
};

export const trackLinkLoginFromRegistrationPage = (properties: Record<string, string>) => ({
  name: EVENTS.LOGIN_CLICKED_FROM_REGISTRATION_PAGE,
  properties: {
    category: CATEGORIES.ONBOARDING,
    ...properties
  }
});

export const trackSignupBannerVisible = () => {
  return analytics.event({
    name: EVENTS.SIGNUP_BANNER_VISIBLE,
    properties: {
      category: CATEGORIES.ONBOARDING
    }
  });
};
