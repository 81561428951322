import React, { useState } from 'react';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import Link from '@nutkit/component-link';
import Panel from '@nutkit/component-panel';
import NewPasswordForm from '@nm-auth/password-reset/src/components/NewPasswordForm';
import { usePasswordReset, useGetCustomerPasswordRules } from '@nm-auth/password-reset/src/hooks';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { Marketing, Signup } from '@nm-utils-lib-web/routes';

import OnboardingLayout from '../../layouts/OnboardingLayout';
import setJISAPassword from '../../services/setJISAPassword';
import postVerificationEmail from '../../services/postVerificationEmail';
import CustomerSupportLink from '../CustomerSupportLink';
import VerifyEmailPage from '../VerifyEmailPage';

//ToDo: move the hook in utils-lib-web
import { usePolling } from './usePolling';

const TRANSLATION_NAMESPACE = 'signup.registerPassword.content';
const GENERIC_ERROR_NAMESPACE = 'signup.common.error.genericAPIWithRetry';
const POLLING_RETIRES = 7;

const RegisterPasswordPage = () => {
  const { t } = useTranslation();
  const [emailAddressToVerify, setEmailAddressToVerify] = React.useState(null);
  const history = useHistory();
  const { search } = useLocation();
  const { reset_password_token = '' } = qs.parse(search, { ignoreQueryPrefix: true });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { passwordState, putNewPasswordRequest, resetNewPasswordState } = usePasswordReset();
  const { publicPasswordRules, privatePasswordRules } = useGetCustomerPasswordRules();
  const {
    stopPolling,
    callFetcher: postVerifyEmail,
    status
  } = usePolling({
    shouldCallOnMount: false,
    maxRetry: POLLING_RETIRES,
    fetcher: postVerificationEmail,
    evaluateResponse: ({ error, data }) => {
      if (error) {
        setError(error);
        stopPolling(error);
      }

      if (data?.emailSent) {
        stopPolling(data);
        setIsLoading(false);

        history.push(`${Signup.REGISTER_PASSWORD_PATH}/verify`);
      }
    }
  });

  const onSubmit = async ({ password }) => {
    setIsLoading(true);
    try {
      const response = await setJISAPassword({ password, token: reset_password_token });

      await postVerifyEmail(response.uuid);

      setEmailAddressToVerify(response.email);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Switch>
      <Route
        exact
        path={`${Signup.REGISTER_PASSWORD_PATH}/verify`}
        render={() => <VerifyEmailPage emailAddressToVerify={emailAddressToVerify} />}
        data-qa="verify-email-path"
        title="signup.verifyEmail.metaTitle"
      />
      <Route exact path={Signup.REGISTER_PASSWORD_PATH}>
        <OnboardingLayout isNarrow listReset={false}>
          <>
            <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
            {(error || status.TIMED_OUT) && (
              <Notification level={notificationLevels.ERROR} dismissable={false}>
                <Trans components={[<CustomerSupportLink />]} i18nKey={GENERIC_ERROR_NAMESPACE} />
              </Notification>
            )}
            <Panel>
              <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subHeading`)}</Heading>
              <NewPasswordForm
                translations={{
                  passwordField: {
                    label: `${TRANSLATION_NAMESPACE}.fields.password.label`,
                    placeholder: `${TRANSLATION_NAMESPACE}.fields.password.placeholder`
                  },
                  confirmPasswordField: {
                    label: `${TRANSLATION_NAMESPACE}.fields.confirmPassword.label`,
                    placeholder: `${TRANSLATION_NAMESPACE}.fields.confirmPassword.placeholder`
                  },
                  newPasswordCheckList: {
                    passwordMustHave: `${TRANSLATION_NAMESPACE}.passwordCheck.passwordMustHave`
                  },
                  continue: `${TRANSLATION_NAMESPACE}.navigation.continue`
                }}
                publicPasswordRules={publicPasswordRules}
                privatePasswordRules={privatePasswordRules}
                passwordState={passwordState}
                putNewPasswordRequest={putNewPasswordRequest}
                resetNewPasswordState={resetNewPasswordState}
                isLoading={isLoading}
                onSubmit={onSubmit}
                dataQa="register-password-form"
              />
            </Panel>
          </>
          <Text textAlign="center" textSize="xs">
            <Trans
              components={[
                <Link href={Marketing.SITE_TERMS_URL} isExternal>
                  {''}
                </Link>,
                <Link href={Marketing.PRIVACY_POLICY_URL} isExternal>
                  {''}
                </Link>
              ]}
              i18nKey={`${TRANSLATION_NAMESPACE}.privacyPolicy`}
            />
          </Text>
        </OnboardingLayout>
      </Route>
    </Switch>
  );
};

export default RegisterPasswordPage;
