import React, { FC, ReactNode } from 'react';
import { Layout, LayoutNarrow } from '@nutkit/layouts';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';

import Footer from '../../components/Footer';

export type OnboardingLayoutProps = {
  children: ReactNode;
  isNarrow?: boolean;
  NotificationComponent?: FC;
  noActionLogo?: boolean;
}

const OnboardingLayout = ({ children, isNarrow = false, NotificationComponent, noActionLogo = false }: OnboardingLayoutProps) => {
  const LayoutComponent = isNarrow ? LayoutNarrow : Layout;
  const showNotification = !!NotificationComponent;

  return (
    <>
      <ThemeProvider themeName={themeNames.DARK}>
        <OrganisationHeader
          translationNameSpace="signup.common.uiSharedOrganisationHeader"
          logoHref={noActionLogo ? null : window.NutmegConfig.MARKETING_HOST}
          showHelp
          noStack={showNotification}
        />
      </ThemeProvider>
      {showNotification && <NotificationComponent />}
      <LayoutComponent>{children}</LayoutComponent>
      <Footer />
    </>
  );
};

export default OnboardingLayout;
