import { useHistory } from 'react-router-dom';
import * as analytics from '@nm-utils-lib-web/analytics';
import { useCallback } from 'react';

const TRACKING_TIMEOUT = 500;

type PushWithTracking = (
  path: string,
  name: string,
  properties: Record<string, string>,
  redirectBeforeTracking?: boolean,
) => () => void // NOTE: return value is a function that returns void (() => clearTimeout())

type UseTrackedHistory = (args: {
  trackingCategory: string
}) => ReturnType<typeof useHistory> & {
  pushWithTracking: PushWithTracking;
}

const useTrackedHistory: UseTrackedHistory = ({ trackingCategory }) => {
  const history = useHistory();
  const pushWithTracking = useCallback<PushWithTracking>(
    (path, name, properties, redirectBeforeTracking = true) => {
      let trackingTimeout: NodeJS.Timeout;

      const navigateToPath = () => {
        clearTimeout(trackingTimeout);
        history.push(path, properties);
      };

      trackingTimeout = setTimeout(navigateToPath, TRACKING_TIMEOUT);

      if (redirectBeforeTracking) {
        navigateToPath();
      }

      analytics.event({
        name,
        properties: { category: trackingCategory, ...properties },
        ...(!redirectBeforeTracking && { callback: navigateToPath })
      });

      return () => clearTimeout(trackingTimeout);
    },
    [history, trackingCategory]
  );

  return {
    ...history,
    pushWithTracking
  };
};

export default useTrackedHistory;
