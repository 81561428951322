import handleError from '../../errorHandler';
import LegacyUserService from '../LegacyUserService';

type GetUser = (options: { customerUuid: string }) => Promise<Awaited<ReturnType<typeof LegacyUserService.get>>['data'] | undefined>;

const getUser: GetUser = async ({ customerUuid }) => {
  try {
    const { data } = await LegacyUserService.get(`/users/${customerUuid}`);

    return data;
  } catch (error) {
    handleError(error, 'Error getting user information');
  }
};

export default getUser;
