import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { func } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { VERIFY_EMAIL_PATH } from '@nm-utils-lib-web/routes/signup';
import { useIdentifyWithUnleashExperiments, LinkWithTracking } from '@nm-utils-lib-web/analytics';
import { trackThunderheadPageView } from '@nm-utils-lib-web/analytics/src/helpers/thunderheadHelpers';
import { eraseSLOCookie } from '@nm-utils-lib-web/authentication';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { useSend } from '@nm-utils-lib-web/network/hooks';
import { Marketing, SupportArticles } from '@nm-utils-lib-web/routes';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import Button from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Heading, headingLevels, Text, textAligns, textStyles } from '@nutkit/component-text';
import Link, { buttonCtas } from '@nutkit/component-link';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Panel from '@nutkit/component-panel';
import PasswordCheckList from '@nm-auth/password-reset/src/components/NewPasswordCheckList';
import { useGetCustomerPasswordRules } from '@nm-auth/password-reset/src/hooks';
import { InputField } from '@nm-ui-shared-lib-web/form';
import Loading from '@nutkit/component-loading';
import { useHandleError } from '@nm-utils-lib-web/error-boundary';

import Altcha from '../Altcha';
import getCustomerAffiliateDetails from '../../../helpers/getCustomerAffiliateDetails';
import { createUser as createUserService } from '../../../services/LegacyUserService';
import {
  trackOnboardingStarted,
  trackLinkLoginFromRegistrationPage,
  useTrackedHistory,
  CATEGORIES,
  EVENTS
} from '../../../tracking';

import EmailVerificationNotification from './EmailVerificationNotification';
import { registerFormValidationSchema } from './registerFormValidationSchema';

const TRANSLATION_NAMESPACE = 'signup.registerPage.registerForm';

const RegisterForm = ({ onSubmit }) => {
  const { search } = useLocation();
  const source = new URLSearchParams(search).get('source');
  const [captchaPayload, setCaptchaPayload] = useState();
  const isCaptchaEnabled = useFlag(Flags.CAPTHCA_ENABLED);
  const altchaRef = useRef(null);
  const passwordChecklistId = 'passwordChecklist';
  const isNewAffilateProviderEnabled = useFlag(Flags.NEW_AFFILIATES_PROVIDER);
  const { t } = useTranslation();
  const [emailNotificationDisplayed, setEmailNotificationDisplayed] = React.useState(false);
  const identifyWithUnleashExperiments = useIdentifyWithUnleashExperiments();
  const history = useTrackedHistory({ trackingCategory: CATEGORIES.ONBOARDING });
  const affiliate = getCustomerAffiliateDetails({ enabled: isNewAffilateProviderEnabled });
  const { triggerError } = useHandleError();
  const { privatePasswordRules, publicPasswordRules, isLoading } = useGetCustomerPasswordRules({
    onError: triggerError
  });
  const {
    error: saveError,
    isLoading: isSubmitting,
    send: createUser
  } = useSend({
    sendFn: createUserService,
    onSuccess: async response => {
      const { userUuid } = response;

      await identifyWithUnleashExperiments(userUuid);

      history.pushWithTracking(
        VERIFY_EMAIL_PATH,
        EVENTS.ONBOARDING_ACCOUNT_CREATED,
        {
          userUuid
        },
        false
      );
    }
  });

  useEffect(() => {
    trackOnboardingStarted();

    trackThunderheadPageView();

    eraseSLOCookie();
  }, []);

  useEffect(() => {
    if (source === 'chase-isa-transfer-offer') {
      history.replace('/register?source=chase');
    }
  }, [source]);

  const onAltchaChange = ev => {
    if (ev.detail.state === 'verified') {
      setCaptchaPayload(ev.detail.payload);
    }
  };

  return (
    <>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      {saveError && (
        <Notification data-qa="register-form-error-notification" level={notificationLevels.ERROR}>
          <Trans
            components={[
              <Link href={SupportArticles.CONTACT_URL} isExternal>
                {''}
              </Link>
            ]}
            i18nKey={`${TRANSLATION_NAMESPACE}.errors.generic_failed_response`}
          />
        </Notification>
      )}
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subHeading`)}</Heading>

        <Formik
          initialValues={{
            email: '',
            password: '',
            passwordRules: { publicPasswordRules, privatePasswordRules }
          }}
          onSubmit={async values => {
            onSubmit(values);

            await createUser({
              ...values,
              affiliate,
              source,
              ...(isCaptchaEnabled && { challenge: captchaPayload || altchaRef.current?.value })
            });
          }}
          validationSchema={registerFormValidationSchema}
          enableReinitialize
          validateOnChange
        >
          {({ errors, values, handleBlur }) => {
            const { email, password } = values;
            const showNotification = !errors.email && emailNotificationDisplayed;

            return (
              <Form data-qa="register-form">
                <Field
                  id="email"
                  type="email"
                  name="email"
                  onBlur={e => {
                    setEmailNotificationDisplayed(true);
                    handleBlur(e);
                  }}
                  aria-label={errors.email}
                  onFocus={() => setEmailNotificationDisplayed(false)}
                  label={t(`${TRANSLATION_NAMESPACE}.fields.email.label`)}
                  placeholder={t(`${TRANSLATION_NAMESPACE}.fields.email.placeholder`)}
                  translationNamespace={TRANSLATION_NAMESPACE}
                  data-qa={`register-form-email-input`}
                  component={InputField}
                  noStack={showNotification}
                />
                {showNotification && <EmailVerificationNotification />}
                <Field
                  id="password"
                  type="password"
                  name="password"
                  aria-labelledby={`${passwordChecklistId}MustHave ${passwordChecklistId}`}
                  disabled={isLoading}
                  label={t(`${TRANSLATION_NAMESPACE}.fields.password.label`)}
                  placeholder={t(`${TRANSLATION_NAMESPACE}.fields.password.placeholder`)}
                  component={InputField}
                  translationNamespace={TRANSLATION_NAMESPACE}
                  aria-label={errors.password}
                  data-qa={`register-form-password-input`}
                  aria-describedby="rule-list-id"
                  required
                />
                {isLoading ? (
                  <Loading />
                ) : (
                  <PasswordCheckList
                    password={password}
                    id={passwordChecklistId}
                    translations={{
                      passwordMustHave: `${TRANSLATION_NAMESPACE}.passwordCheck.passwordMustHave`
                    }}
                    passwordRules={publicPasswordRules}
                    data-qa="password-check-list"
                  />
                )}
                {isCaptchaEnabled && <Altcha ref={altchaRef} onStateChange={onAltchaChange} />}
                <ButtonGroup stackUp stackOnMobile stack align={buttonGroupAlignments.JUSTIFY}>
                  <Link href={Marketing.MARKETING_SITE_URL} button buttonCta={buttonCtas.SECONDARY} data-qa="back">
                    {t(`${TRANSLATION_NAMESPACE}.journeyNavigation.back.label`)}
                  </Link>
                  <Button
                    type="submit"
                    data-qa={`register-form-submit-button`}
                    disabled={
                      !email ||
                      !!errors.email ||
                      !password ||
                      !!errors.password ||
                      !!errors.confirmPassword ||
                      (isCaptchaEnabled && !captchaPayload)
                    }
                    isLoading={isSubmitting}
                  >
                    {t(`${TRANSLATION_NAMESPACE}.journeyNavigation.continue.label`)}
                  </Button>
                </ButtonGroup>
              </Form>
            );
          }}
        </Formik>
        <Text textAlign={textAligns.CENTER}>
          <Trans
            components={[
              <LinkWithTracking
                eventPayload={trackLinkLoginFromRegistrationPage({ hasSeenError: saveError })}
                href={window.NutmegConfig.LOGIN_HOST}
              >
                {t(`${TRANSLATION_NAMESPACE}.logIn`)}
              </LinkWithTracking>
            ]}
            data-qa="already-have-account-translation"
            i18nKey={`${TRANSLATION_NAMESPACE}.alreadyHaveAnAccount`}
          />
        </Text>
      </Panel>
      <Text textAlign={textAligns.CENTER} textStyle={textStyles.TEXT_2}>
        <Trans
          components={[
            <Link href={Marketing.SITE_TERMS_URL} isExternal>
              site terms
            </Link>,
            <Link href={Marketing.PRIVACY_POLICY_URL} isExternal>
              privacy policy
            </Link>
          ]}
          i18nKey={`${TRANSLATION_NAMESPACE}.termsAndConditions`}
        />
      </Text>
    </>
  );
};

RegisterForm.propTypes = {
  onSubmit: func.isRequired
};

export default RegisterForm;
