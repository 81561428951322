import cookies from 'browser-cookies';
import { ORGANISATIONS, getOrganisation } from '@nm-utils-lib-web/organisations';

const PROVIDER = 'rakuten';
const AFFILIATE_ID = 'affiliateId';
const AFFILIATE_LANDING_TIME = 'affiliateLandingTime';
const SUPPORTED_ORGS = [ORGANISATIONS.NUTMEG_GBR_BIGBEAR, ORGANISATIONS.CHASE_GBR];

type GetCustomerAffiliateDetails = (args: {
  enabled: boolean;
  currentUrl?: string
}) => null | {
  id: string;
  landingTime: number;
  provider: string;
}

const getCustomerAffiliateDetails: GetCustomerAffiliateDetails = ({ enabled, currentUrl = document.location.href }) => {
  const url = new URL(currentUrl);
  const queryParams = new URLSearchParams(url.search);
  const affiliateId = cookies.get(AFFILIATE_ID) || queryParams.get(AFFILIATE_ID);
  const landingTime = cookies.get(AFFILIATE_LANDING_TIME) || queryParams.get(AFFILIATE_LANDING_TIME);

  if (!enabled) {
    return null;
  }

  // @see - https://stackoverflow.com/questions/69690541/how-to-check-if-string-is-included-in-string-literal-type-without-using-a-type-a
  // @see - https://stackoverflow.com/questions/53033854/why-does-the-argument-for-array-prototype-includessearchelement-need-the-same/53035048#53035048
  // @see - https://github.com/microsoft/TypeScript/issues/26255
  // @ts-expect-error - Array.prototype.include can only check on subsets. See discussions linked above
  if (!SUPPORTED_ORGS.includes(getOrganisation())) {
    return null;
  }

  if (!affiliateId) {
    return null;
  }

  if (!landingTime) {
    return null;
  }

  return {
    id: affiliateId,
    landingTime: parseInt(landingTime),
    provider: PROVIDER
  };
};

export default getCustomerAffiliateDetails;
