import { ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import handleError from '../../errorHandler';
import NonAuthenticatedUserService from '../NonAuthenticatedUserService';

import { CreateUserBody } from './types';

const authOrgMap: Record<string, string> = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: 'nutmeg',
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: 'jlp',
};

const fetchOrganisationCode = (source?: string): string => {
  const isChaseIsaPromoUser = source === 'chase';

  if (isChaseIsaPromoUser) {
    return ORGANISATIONS.CHASE_GBR;
  }

  return authOrgMap[global.NutmegConfig.ORGANISATION || ORGANISATIONS.NUTMEG_GBR_BIGBEAR];
};

export type CreateUser = (options: {
  email: string;
  password: string;
  affiliate?: { id: string; landingTime: number; provider: string };
  source?: string;
  challenge?: string;
}) => Promise<CreateUserBody | undefined>;

const createUser: CreateUser = async ({ email, password, affiliate, source, challenge }) => {
  try {
    const { data } = await NonAuthenticatedUserService.post('/create_user', {
      email,
      password,
      organisationCode: fetchOrganisationCode(source),
      affiliate: affiliate || undefined,
      challenge,
    });

    /**
     * NOTE: we're missing a validation layer like yup or zod to confirm at
     * runtime the values we expect from the API are actually present. As a
     * result, there's no type safe way to narrow it down. For the time being we
     * use `as`.
     */
    return data as CreateUserBody;
  } catch (error) {
    handleError(error, 'Error creating user');
  }
};

export default createUser;
